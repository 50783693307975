
import * as __star__ from '@clayui/pagination-bar';

const {
default: __default__,
ClayPaginationBarWithBasicItems,
DropDown,
PaginationBar,
Results,
__esModule
} = __star__;

export {
ClayPaginationBarWithBasicItems,
DropDown,
PaginationBar,
Results,
__esModule
};
export default __default__;
